<template>
    <div class="order-detail__body">
        <header class="order-detail__body_row">
            <p class="orders-details-tabs__tab-description">
                <InformationOutline />
                Here is the list of all support tickets open with regards to this project.
                You can create a new support ticket directly from this page.
            </p>
        </header>

        <div class="order-detail__body_row">
            <button
                class="btn-base btn-main"
                @click="scrollToSupportForm()"
            >
                Create a support ticket
            </button>

            <List
                :tickets="tickets"
                :total="counter"
                :show-loadmode="lastPage > search.page"
                :loading="isLoading"
                @load-more="loadMore"
            />
        </div>
        <div class="order-detail__body_row">
            <div
                v-if="showCreateTicketForm"
                id="create-ticket"
                class="create-support-ticket orders-details-tabs__create"
            >
                <support-form
                    :order-id="order.orderid"
                    @supportFormCreated="onSupportFormCreated"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'

import Api from '@/helpers/api/index.js';
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { eventBus } from '@/helpers/event-bus';

import InformationOutline from 'mdi-vue/InformationOutline';
import SupportForm from '@/components/support/SupportTicketForm.vue'
import List from '@/components/account/HelpCenter/List.vue'

export default {
    components: {
        InformationOutline,
        SupportForm,
        List
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showCreateTicketForm: false,
            tickets: [],
            counter: null,
            search: {
                page: 1,
                per_page: 10,
                search_for: this.order.orderid,
                search_by: 'orderid'
            }
        }
    },
    computed: {
        lastPage() {
            return Math.ceil(this.counter / this.search.per_page)
        }
    },
    async created() {
        await this.getTicketList()
    },
    methods: {
        async loadMore() {
            this.search.page += 1
            await this.getTicketList(false)
        },
        async getTicketList(withCout = true) {
            try {
                this.isLoading = true
                let requests = []
                requests = [...requests, Api.get('/api/support/auth/list-by-params', removeEmptyParamsFromRequest({ mode: 'list', ...this.search }))]
                if (withCout) {
                    requests = [...requests, Api.get('/api/support/auth/list-by-params', removeEmptyParamsFromRequest({ mode: 'count', ...this.search }))]
                }
                const responses = await Promise.all(requests)
                const { data } = responses[0]
                if (responses[1]) {
                    const { data: totals } = responses[1]
                    this.counter = totals.count
                }
                this.tickets = [...this.tickets, ...data.data]
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        scrollToSupportForm() {
            this.showCreateTicketForm = !this.showCreateTicketForm
            setTimeout(() => {
                const supportForm = document.querySelector('#create-ticket')
                if (supportForm) {
                    const ScrollTo = scroller();
                    ScrollTo(supportForm, {
                        duration: 600,
                        offset: -85
                    });
                }
            }, 300);
        },
        async onSupportFormCreated(payload) {
            this.showCreateTicketForm = false
            this.tickets = []
            await this.getTicketList()
            this.$emit('supportFormCreated', payload)
        },
        markAsRead(payload) {
            this.$emit('supporMarkAsRead', payload)
        }
    }
}
</script>

<style lang="scss">
.help-center {
    .tickets {
        padding: 0;
        margin-top: 30px;
    }

    .orders-details-tabs__support-tickets {
        padding: 30px 25px 10px;
        padding-top: 0;
    }

    .support-form {
        margin: 0;
        box-shadow: none;
    }
}
</style>
